import { now } from '@/@core/utils/filter'
import { downloadServiceReport, fetchServicePurchasesSummary, fetchServiceReport, fetchServiceReports, storeServiceReport } from '@/api/reporting'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import mStore from '@/modules/merchant/store'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

const cached = []

export default function useServiceReport() {
  const serviceSummary = ref([])

  const serviceSummaryColumns = [
    { text: 'ORDER NUMBER', value: 'order_number', sortable: false },
    { text: 'ORDER DATE', value: 'order_date', sortable: false },
    { text: 'MERCHANT', value: 'merchant_name', sortable: false },
    { text: 'SKU', value: 'sku', sortable: false },
    { text: 'LEGAL NAME', value: 'legal_name', sortable: false },
    { text: 'EMAIL', value: 'email', sortable: false },
    { text: 'PHONE', value: 'phone', sortable: false },
    { text: 'STAFF ID', value: 'staff_id', sortable: false },
    { text: 'FROM', value: 'service_from_date', sortable: false },
    { text: 'TO', value: 'service_to_date', sortable: false },
    { text: 'LOCATION', value: 'service_location', sortable: false },
    { text: 'PARTICIPANTS', value: 'participants', sortable: false },
  ]

  const serviceReports = ref([])
  const serviceReportsTotal = ref(0)
  const serviceReportsColumns = [
    { text: 'STATUS', value: 'status', sortable: false },
    { text: 'FROM', value: 'report_from_date', sortable: false },
    { text: 'TO', value: 'report_to_date', sortable: false },
    { text: 'COUNT', value: 'count' },
    { text: 'MERCHANT', value: 'merchant', sortable: false },
    { text: 'SENT AT', value: 'sent_at' },
    { text: 'ACTIONS', value: 'actions' },
  ]

  const loading = ref(false)

  const options = ref(
    cached[0] || {
      sortBy: ['created_at'],
      sortDesc: [true],
      itemsPerPage: 10,
      page: 1,
    },
  )

  const form = ref(
    cached[1] || {
      from: now().subtract(6, 'day').startOf('day').format('YYYY-MM-DD'),
      to: now().subtract(1, 'day').endOf('day').format('YYYY-MM-DD'),
    },
  )

  const merchantId = computed(() => mStore.state.current?.id || undefined)

  // const cachable = [form, merchantId]

  // const cache = () => {
  //   cachable.forEach((variable, index) => {
  //     cached[index] = variable.value
  //   })
  // }

  const loadServicesSummary = () =>
    fetchServicePurchasesSummary({
      merchant_id: merchantId.value,
      created_at_from: now(form.value.from).startOf('day').toString(),
      created_at_to: now(form.value.to).endOf('day').toString(),
    }).then(({ data }) => {
      serviceSummary.value = data.data.items
    })

  const loadServiceReports = () =>
    fetchServiceReports(
      useTableOptions(options.value, {
        merchant_id: merchantId.value,
        report_from_date: now(form.value.from).startOf('day').toString(),
        report_to_date: now(form.value.to).endOf('day').toString(),
      }),
    ).then(({ data }) => {
      serviceReports.value = data.data.records
      serviceReportsTotal.value = data.data.pagination.total
    })

  const attemptDownloadReport = async reportable => {
    const {
      data: { data: newReportable },
    } = await fetchServiceReport(reportable.id)
    const { report } = newReportable

    if (report && report.status === 'completed') {
      // downloadServiceReport(reportable.id) // popup blocked =/
      await loadServiceReports()
      useNotifySuccess({
        content: `Your service report (${report.report_from_date} - ${report.report_to_date}) is ready. You may download it now. And make browser "allow popup" for "auto downloading" in the future.`,
        timeout: 10000,
      })
      downloadServiceReport(reportable.id)
    } else {
      setTimeout(() => attemptDownloadReport(reportable), 5000)
    }
  }

  const generateServiceReport = async () => {
    loading.value = true

    const res = await storeServiceReport({
      merchant_id: merchantId.value,
      created_at_from: now(form.value.from).startOf('day').toString(),
      created_at_to: now(form.value.to).endOf('day').toString(),
    }).catch(err => {
      useNotifyErrors(err)
    })
    await loadServiceReports()

    const {
      data: { data: reportable },
    } = res

    loading.value = false

    attemptDownloadReport(reportable)

    return res
  }

  const reloadAll = async () => {
    loading.value = true
    options.value.page = 1
    try {
      // await loadServicesSummary()
      await loadServiceReports()
    } catch (err) {
      useNotifyErrors(err)
    }
    loading.value = false
  }

  const cachable = [options, form, merchantId]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, async () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    // loading.value = true
    await loadServiceReports()

    // loading.value = false
    cache()
  })

  onMounted(() => {
    reloadAll()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    serviceSummary,
    serviceSummaryColumns,
    serviceReports,
    serviceReportsTotal,
    serviceReportsColumns,

    loading,
    options,
    form,
    reloadAll,
    generateServiceReport,
    downloadServiceReport,
  }
}
