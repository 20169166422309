<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="serviceReportsColumns"
        :items="items"
        :server-items-length="serverItemsLength"
        :loading="loading"
        :options.sync="tempOptions"
      >
        <template #[`item.status`]="{item}">
          <span>
            {{ item.report.status }}
          </span>
          <v-progress-circular
            v-if="item.report.status === 'in_progress'"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>

        <template #[`item.report_from_date`]="{item}">
          <span>
            {{ item.report.report_from_date | dateSimple }}
          </span>
        </template>

        <template #[`item.report_to_date`]="{item}">
          <span>
            {{ item.report.report_to_date | dateSimple }}
          </span>
        </template>

        <template #[`item.merchant`]="{item}">
          <span>{{ item.merchant? item.merchant.code : 'ALL' }}</span>
        </template>

        <template #[`item.sent_at`]="{item}">
          <span v-if="item.sent_at">
            {{ item.sent_at | dateSimple }}
          </span>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="downloadReport(item)"
              >
                <v-list-item-title>
                  <span>Download Report (xlsx)</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="resendReport(item)"
              >
                <v-list-item-title>
                  <span>Resend to..</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <resend-service-report-dialog
      v-if="sendReport"
      :show="true"
      :report-id="sendReport.id"
      :default-email="defaultEmail"
      @close="sendReport = false"
    >
    </resend-service-report-dialog>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { mdiDotsVertical } from '@mdi/js'

import { useNotifyErrors } from '@/composables'
import merchantStore from '@/modules/merchant/store'
import { dateSimple, money } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'
import useServiceReports from '../useServiceReports'
import ResendServiceReportDialog from './ResendServiceReportDialog.vue'

export default {
  filters: { money, dateSimple },

  components: { ResendServiceReportDialog },

  props: {
    items: { type: Array, required: true },
    serverItemsLength: { type: Number, required: true },
    options: { type: Object, required: true },
    loading: { type: Boolean, required: true },
  },

  setup(props, { emit }) {
    const {
      serviceReportsColumns,

      downloadServiceReport,
    } = useServiceReports()

    const xlsxBlobUrl = ref(null)
    const sendReport = ref(null)
    const defaultEmail = ref(null)

    const downloadReport = report => {
      if (report.report.status !== 'completed') {
        return useNotifyErrors({
          title: 'Try again after at least 1 minute',
          content: 'The Report generation is not completed yet.',
        })
      }

      downloadServiceReport(report.id).then(({ data }) => {
        xlsxBlobUrl.value = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
        window.open(xlsxBlobUrl.value)
      })
    }

    const resendReport = report => {
      sendReport.value = report
      const merchant = merchantStore.state.all.find(m => m.code === report.merchant.code)
      const setting = merchant?.settings.find(s => s.key === 'service_report_email')
      defaultEmail.value = setting?.value || null
    }

    const tempOptions = ref({ ...props.options })

    watch(tempOptions, () => {
      emit('update:options', tempOptions.value)
    })

    return {
      serviceReportsColumns,
      tempOptions,

      t,

      downloadReport,
      resendReport,
      sendReport,
      defaultEmail,

      // icons
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
