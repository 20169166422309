<template>
  <div id="user-list">
    <!-- list filters -->
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="serviceSummaryColumns"
        :items="items"
        :server-items-length="items.length"
        :loading="loading"
        hide-default-footer
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n';
import { } from '@mdi/js';

// sidebar
import { money } from '@core/utils/filter';
import useServiceReports from '../useServiceReports';

export default {
  filters: { money },

  props: {
    items: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },

  setup() {
    const { serviceSummaryColumns } = useServiceReports()

    return {
      serviceSummaryColumns,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
